import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {Form, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {BaixasPorContaService} from './baixasPorConta.service';


import {Data} from '@angular/router';

interface Detalhe {
    cnpj: string;
    obrigacao: string;
    qtde: number;
    conta: string;
    ids: [];
}

@Component({
    selector: 'app-creditos',
    templateUrl: './baixasPorConta.component.html',
    styleUrls: ['./baixasPorConta.component.scss']
})
export class BaixasPorContaComponent extends AbstractListTable<any> implements OnInit, TabHandlerInterface {


    loadings: {
        table: boolean,
        detalhe: any,
        reprocessar: any
    } = {
        table: false,
        detalhe: [],
        reprocessar: []
    };

    registros: {
        conta: { identificador: string, nome: string }, qtdErros: number, ids: string[], index: number,
        detalhe: Detalhe[]
    }[] = [];

    listOfCurrentPageData: readonly Data[] = [];
    modalOptions: { visible: boolean, detalhe: Detalhe[] }
        = {
        visible: false,
        detalhe: [],
    };


    constructor(
        private fb: UntypedFormBuilder,
        private service: BaixasPorContaService,
        private toastService: ToastrService
    ) {
        super(service, null, BaixasPorContaService);


    }

    ngOnInit() {

        this.queryTable();

    }

    // tslint:disable-next-line:no-shadowed-variable

    openTab(componentName: string, queryParams?: string, data?: {}) {
    }

    queryTable(): void {

        // const filtros = this.formFiltrar.formGroup.value;
        //
        // for (let [chave, valor] of Object.entries(filtros)) {
        //     if (valor) {
        //         const val: any = valor;
        //         if (typeof val.getDate === 'function') {
        //             valor = formataDatas(valor, 'AAAA-MM-DD');
        //         }
        //         this.currentParams.filter.push({key: chave, value: valor});
        //     }
        // }

        // this.countActiveFilters();

        this.loadings.table = true;

        this.service.getBaixas().subscribe((response) => {

            this.registros = [];
            this.registros = response?.data || response;

            this.loadings.table = false;
        }, error => {
            this.loadings.table = false;
            this.toastrService.error(error.error.message);
        });

    }


    getDetalhe(visible: boolean, index: number = 0, detalhe: Detalhe[] = []) {
        this.loadings.detalhe[index] = true;

        this.modalOptions = {
            visible,
            detalhe
        };
        this.loadings.detalhe[index] = false;


    }

    onCurrentPageDataChange(listOfCurrentPageData: readonly Data[]): void {
        this.listOfCurrentPageData = listOfCurrentPageData;
        this.refreshCheckedStatus();
    }

    refreshCheckedStatus(): void {
        const listOfEnabledData = this.listOfCurrentPageData.filter(({disabled}) => !disabled);
    }

    async onReprocessarPorCnpj(index: string, conta: string, ids: string[] = []) {
        this.loadings.reprocessar[index] = true;

        try {
            const retornoReprocessar = await this.service.reprocessarIds(conta, ids).toPromise();


            const filtros: { key: string, value: string }[] = [];
            filtros.push({key: 'identificador', value: conta});

            const retornoDetalhe = await this.service.getBaixas(filtros).toPromise();

            this.modalOptions.detalhe = retornoDetalhe[0]?.detalhe ?? [];

            this.loadings.reprocessar[index] = false;

            this.toastService.success('Dados reprocessados com sucesso.');


        } catch (rss) {
            this.toastService.error(rss.error.message);
            this.loadings.reprocessar[index] = false;
        }


    }
}
