import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {Form, UntypedFormBuilder} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {DispositivosAgentService} from './dispositivos-agent.service';


import {Data} from '@angular/router';
import {NzTableQueryParams} from "ng-zorro-antd/table";
import {Pagination} from "@models/pagination.model";
import {environment} from "../../../../environments/environment";
import {FiltrosService} from "@services/filtros.service";
import {formataDatas} from "../../../shared/components-helper";


interface Registro {
    consumoTamanho: number;
    id: string;
    identificador: string;
    conta_nome: string;
    consumoDesde: string;
    ultimoLogin: string;
    ultimaTransmissao: string;
}

@Component({
    selector: 'app-dispositivos-agent',
    templateUrl: './dispositivos-agent.component.html',
    styleUrls: ['./dispositivos-agent.component.scss']
})
export class DispositivosAgentComponent extends AbstractListTable<any> implements OnInit {
    loadings: {
        table: boolean,
        drawer: boolean,
        modalFiltros: boolean,
    } = {
        table: false,
        drawer: false,
        modalFiltros: false,
    };
    registros: Registro[] = [];
    visibles: { drawer: boolean } = {drawer: false};

    registrosDetalhe: {
        id: string,
        mac: string,
        status: number,
        usuario_nome: string,
        dispositivo: string,
        ip: string,
        ipInterno: string,
        versao: string,
        ultimoLogin: string,
        ultimaTransmissao: string,
        tamanho: number,
    }[] = [];

    optionsDrawer: {
        titulo: string;
    } = {
        titulo: '',
    };
    comboContas: { label: string, value: string }[] = [];


    constructor(
        private fb: UntypedFormBuilder,
        private service: DispositivosAgentService,
        private toastService: ToastrService,
        private filtrosService: FiltrosService
    ) {
        super(service, null, DispositivosAgentService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                identificador: [null, null],
            })
        };


    }

    ngOnInit() {

        this.getContas();

    }

    async getContas() {
        this.loadings.modalFiltros = true;

        try {
            this.comboContas = [];
            this.comboContas = await this.filtrosService.contas().toPromise();
            this.loadings.modalFiltros = false;
        } catch (error) {
            this.loadings.modalFiltros = false;
        }
    }


    getRegistros(params: NzTableQueryParams, search: string = '') {

        this.currentParams = params;
        this.currentSearch = search;

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.countActiveFilters();


        const url = environment.apiUrl + '/monitoramento/dispositivos-agent/tabela';
        this.loadings.table = true;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.registros = [];
            this.registros = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadings.table = false;
        }, (res) => {
            this.toastService.error('Problemas ao obter dados do servidor.');
            this.loadings.table = false;
        });

    }

    async onModalDetalhe(visible: boolean, registro: Registro = null) {

        this.visibles.drawer = visible;

        if (visible) {
            this.loadings.drawer = true;
            this.optionsDrawer.titulo = registro.conta_nome;

            try {
                const res = await this.service.detalhe(registro.identificador).toPromise();
                this.registrosDetalhe = [];
                this.registrosDetalhe = res.data ?? [];
                this.loadings.drawer = false;
            } catch (error) {
                this.loadings.drawer = false;
                this.registrosDetalhe = [];
            }

        }


    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.getRegistros(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    resetSearch() {

        this.formFiltrar.formGroup.reset();
        this.getRegistros({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],

        });
    }
}
