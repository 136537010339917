<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Contas"></nz-page-header>
<nz-content>
    <nz-card [ngClass]="status ? 'card-fullscreen' : ''" [nzExtra]="cardExtra" nzTitle="Contas"
             [nzLoading]="loading" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">


                <div class="nav-item">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);"
                            *ngIf="hasPermissao(screenPermissoes.cadastrar, currentPermissoes)">
                        Cadastrar
                    </button>
                </div>

                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="onAlterarVisibilidadeColetiva()" [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item
                                    *ngIf="hasPermissao(screenPermissoes.visibilidades, currentPermissoes)">
                                    Alterar Visibilidade
                                </li>
                                <li (click)="onAlterarClassificacaoColetiva()"
                                    [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                    Alterar Classificação
                                </li>
                            </ul>
                        </li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <form>
                        <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch" name="'mySearch' "
                               #mySearch (keyup)="onSearchKeydown($event, mySearch.value)">
                    </form>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button nzType="default"
                        style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                        (click)="updateTable();" title="Atualizar">
                    <i nz-icon nzType="reload"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplate"
                  class="table-small fonte-doze">
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th [nzShowSort]="false"></th>
                <th nzColumnKey="identificador" [nzSortFn]="true" nzAlign="center">Identificador(es)</th>
                <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                <th nzColumnKey="classificacao" [nzSortFn]="true">Classificação</th>
                <th nzAlign="center" nzColumnKey="">Infraestrutura</th>
                <th nzColumnKey="plano_descricao" [nzSortFn]="true" nzAlign="center">Plano</th>
                <th nzColumnKey="visibilidade_descricao">Visibilidade</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true" [nzSortOrder]="'descend'">Cadastro Data
                </th>
                <th *ngIf="false" nzColumnKey="ativo" [nzSortFn]="true">Ativo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let conta of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(conta.id, $event)"
                    [nzChecked]="checkedItems.has(conta.id)"
                    [nzDisabled]="conta.disabled">
                </td>
                <td style="text-align: center; width: 100px;">
                    <img nz-image class="imagem-logotipo" [nzSrc]="'data:image/png;base64,' + conta.logotipo"
                         [nzFallback]="fallbackAvatar"
                         alt="{{conta.identificador}}" nzDisablePreview>
                </td>
                <td nzAlign="center" style="max-width: 200px; white-space: normal">
                    <a nz-button nzType="link" style="white-space: normal; width: 100%;"
                       (click)="openTab('/conta/detalhe', conta.id, {id: conta.id, nome: conta.nome})">
                        {{stringIdentificadores(conta?.identificadores)}}
                    </a>
                </td>
                <td>{{conta?.nome}}
                    <nz-tag [nzColor]="'#f50'" *ngIf="!conta?.ativo" style="margin-left: 10px; font-size: 10px">
                        Inativo
                    </nz-tag>
                    <nz-tag nzColor="warning" *ngIf="conta?.implantacao" style="margin-left: 10px; font-size: 10px">
                        Em Implantação
                    </nz-tag>

                    <nz-tag [nzColor]="'#d46b08'" *ngIf="conta?.manutencao" style="margin-left: 10px; font-size: 10px">
                        Em Manutenção
                    </nz-tag>
                </td>
                <td>{{conta?.tipo}}
                    <nz-tag [nzColor]="'#2db7f5'" *ngIf="conta?.legado" style="margin-left: 10px; font-size: 10px">
                        Legado
                    </nz-tag>
                </td>
                <td>{{conta?.classificacao ? labelClassificacao[conta?.classificacao] : '-'}}
                </td>
                <td nzAlign="center" style="min-width: 110px">

                    <nz-space>
                        <i nz-typography class="fas fa-at"
                           title="Status Domínio ({{descricaoStatus[conta?.infraestrutura?.dominioStatus ? conta?.infraestrutura?.dominioStatus : 1]}})"
                           [ngStyle]="{'color': corStatus[conta?.infraestrutura?.dominioStatus ? conta?.infraestrutura?.dominioStatus : 1]}"
                        ></i>
                    </nz-space>
                    <nz-space>
                        <i nz-typography class="fas fa-database"
                           title="Status Banco de dados ({{descricaoStatus[conta?.infraestrutura?.databaseStatus ? conta?.infraestrutura?.databaseStatus : 1]}})"
                           [ngStyle]="{'color': corStatus[conta?.infraestrutura?.databaseStatus ? conta?.infraestrutura?.databaseStatus : 1]}"></i>
                    </nz-space>
                    <nz-space>
                        <i nz-typography class="fab fa-aws"
                           title="Status S3 ({{descricaoStatus[conta?.infraestrutura?.s3Status ? conta?.infraestrutura?.s3Status : 1]}})"
                           [ngStyle]="{'color': corStatus[conta?.infraestrutura?.s3Status ? conta?.infraestrutura?.s3Status : 1]}"
                        ></i>
                    </nz-space>
                    <nz-space>
                        <i nz-typography class="fas fa-server"
                           title="Status Embedded ({{descricaoStatus[conta?.infraestrutura?.embeddedStatus ? conta?.infraestrutura?.embeddedStatus : 1]}})"
                           [ngStyle]="{'color': corStatus[conta?.infraestrutura?.embeddedStatus ? conta?.infraestrutura?.embeddedStatus : 1]}"></i>
                    </nz-space>
                    <nz-space>
                        <i nz-typography class="fa fa-external-link"
                           [ngStyle]="conta?.infraestrutura?.dominioStatus === 2 ? {'color': '#1890ff',
                                   'cursor': 'pointer'} : {'color' : '#c2c2c2'}"
                           [title]="conta?.infraestrutura?.dominioStatus === 2 ? conta.identificador + '.mastertax.app/' : ''"
                           (click)="link(conta?.infraestrutura?.dominioStatus, conta?.identificador);"
                        ></i>
                    </nz-space>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link"
                       [disabled]="!conta?.plano_id"
                       (click)="openTabPlano('/administracao/planos/plano-detalhe', conta.plano_id, {id: conta.plano_id, plano_descricao: conta?.plano_descricao})">
                        {{conta?.plano_descricao ? conta?.plano_descricao : '-'}}
                    </a>
                </td>
                <td *ngIf="true">
                    <nz-tag *ngIf="!conta.visibilidade_descricao">Geral</nz-tag>
                    <nz-tag *ngIf="conta?.visibilidade_descricao"
                            style="cursor: pointer"
                            nz-tooltip="true" [nzTooltipTitle]="'Abrir visibilidade'"
                            (click)="openTab('/administracao/visibilidades/visibilidade-detalhe',
                            conta.visibilidade_id, {id: conta.visibilidade_id, nome: conta.visibilidade_descricao})"

                    >{{conta?.visibilidade_descricao}}</nz-tag>
                </td>
                <td nzAlign="center">{{conta?.created_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td *ngIf="false" nzAlign="center">{{getStatusLabel(conta?.ativo)}}</td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>
<ng-template #cardExtra>
    <div class="nav-item d-flex">


        <button nz-button nz-dropdown nzType="primary" (click)="modalCadastrar(true)" style="margin-right: 10px;">
            Cadastrar
        </button>
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
            <button nz-button nzType="primary"
                    style="margin-right: 10px;"
                    (click)="modalFiltrar(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                nzType="default"
                style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li (click)="onAlterarVisibilidadeColetiva()"
                            [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                            Alterar Visibilidade
                        </li>
                        <li (click)="onAlterarClassificacaoColetiva()"
                            [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                            Alterar Classificação
                        </li>
                    </ul>
                </li>
                <li nz-menu-item nzDisabled>Importar para Excel</li>
                <li nz-menu-item nzDisabled>Exportar para Excel</li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="resetSearch();">
            Limpar
        </button>
        <button nz-button nzType="default" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'" style="padding: 0 8px;"></i>
        </button>
    </div>
</ng-template>
<nz-modal *ngIf="visibilidadeOptions?.length" [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Visibilidades">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedVisibilidades"
                   [disabled]="limparVisibilidades"
                   [nzPlaceHolder]="'Selecione a visibilidade'">
            <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="limparVisibilidades">Sem visibilidade</label>
    </ng-template>

    <ng-template #modalFooter>
        <button (click)="addNewVisibilidade()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova visibilidade
        </button>
        <button (click)="vincularVisibilidades()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewVisibilidade()" (nzOnOk)="adicionarNovaVisibilidade()"
          [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Visibilidade">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaVisibilidade"></label>
            <input [(ngModel)]="newVisibilidade" id="novaVisibilidade" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Identificador</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.identificador !== '' && formFiltrar.formGroup.value.identificador !== null">
                    <input nz-input type="text" formControlName="identificador" placeholder="Idenificador"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.nome !== '' && formFiltrar.formGroup.value.nome !== null">
                    <input nz-input type="text" formControlName="nome" placeholder="Nome"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.tipo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option
                                [nzLabel]="'Contabilidade'" [nzValue]="'Contabilidade'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Empresarial'" [nzValue]="'Empresarial'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Monstrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Classificação</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.classificacao">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="classificacao">
                        <nz-option [nzLabel]="'Sem classificação'" [nzValue]="'semClassificacao'"></nz-option>
                        <nz-option [nzLabel]="'Prime'" [nzValue]="'prime'"></nz-option>
                        <nz-option [nzLabel]="'Super'" [nzValue]="'super'"></nz-option>
                        <nz-option [nzLabel]="'Excellence'" [nzValue]="'excellence'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Plano</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.plano_id">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="plano_id">
                        <nz-option nzLabel="Sem plano" nzValue="semPlano"></nz-option>
                        <nz-option *ngFor="let opt of comboPlanos"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em Implantação</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.implantacao">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="implantacao">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em Manutencao</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.manutencao">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="manutencao">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.visibilidade">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="visibilidade_id">
                        <nz-option nzLabel="Sem visibilidade" nzValue="semVisibilidade"></nz-option>
                        <nz-option *ngFor="let opt of comboVisibilidades"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5">Cadastrado em</nz-form-label>
                <div nz-col [nzSpan]="14">
                    <div nz-row>
                        <nz-form-control nz-col [nzSpan]="12"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.cadastroDataDe">
                            <nz-date-picker formControlName="cadastroDataDe" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="De:"
                            ></nz-date-picker>
                        </nz-form-control>
                        <nz-form-control nz-col [nzSpan]="12" class="text-right"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.cadastroDataAte">
                            <nz-date-picker formControlName="cadastroDataAte" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="Até:"></nz-date-picker>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportar" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar Nova Conta'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCadastrar.formGroup"
              style="width: 100%;" [nzAutoTips]="autoTips">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Identificador</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="identificador" placeholder="Identificador" maxlength="20"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="nome" placeholder="Nome"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option
                                [nzLabel]="'Empresarial'" [nzValue]="'Empresarial'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Contabilidade'" [nzValue]="'Contabilidade'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5">Classificação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="classificacao">
                        <nz-option [nzLabel]="'Prime'" [nzValue]="'prime'"></nz-option>
                        <nz-option [nzLabel]="'Super'" [nzValue]="'super'"></nz-option>
                        <nz-option [nzLabel]="'Excellence'" [nzValue]="'excellence'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5">Em Implantação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="implantacao">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5">Infraestrutura</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipoInfraestrutura">
                        <nz-option [nzLabel]="'Compartilhada'" [nzValue]="'compartilhada'"></nz-option>
                        <nz-option [nzLabel]="'Dedicada'" [nzValue]="'dedicada'"></nz-option>
                        <nz-option [nzLabel]="'Semi-Dedicada'" [nzValue]="'semi_dedicada'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5">Contratado</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="contratado">
                        <nz-option *ngFor="let e of empresaContratada" nzLabel="{{e.nome + ' - ' + (e.cnpj | cpfCnpj)}}"
                                   [nzValue]="e.cnpj"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-divider nzPlain nzText="Responsável" nzOrientation="center"></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="responsavelNome" placeholder="Nome do Responsável">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Documento</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="responsavelDocumento"
                           placeholder="Documento do Responsável"
                           (keydown)="verificaMascaraDoc($event)"
                           (keyup)="verificaMascaraDocBack($event)"
                           autocomplete="off" mask="{{maskDocFilter}}">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formClassificacao.modalVisible" [nzTitle]="'Alterar Classificação'" [nzClosable]="true"
          (nzOnCancel)="modalClassificacao(false)" [nzWidth]="448">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formClassificacao.formGroup"
              style="width: 100%;" [nzLayout]="'vertical'">
            <nz-form-item>
                <nz-form-label nzRequired nzSpan="20" nzOffset="2">Classificação</nz-form-label>
                <nz-form-control nzSpan="20" nzOffset="2" nzErrorTip="Campo Obrigatório!">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="classificacao">
                        <nz-option [nzLabel]="'Sem classificação'" [nzValue]="'semClassificacao'"></nz-option>
                        <nz-option [nzLabel]="'Prime'" [nzValue]="'prime'"></nz-option>
                        <nz-option [nzLabel]="'Super'" [nzValue]="'super'"></nz-option>
                        <nz-option [nzLabel]="'Excellence'" [nzValue]="'excellence'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalClassificacao(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.gravandoClassificassao"
                (click)="confirmaAlterarClassificacao()">Confirmar
        </button>
    </div>
</nz-modal>
