import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class StatusBaixasService extends AbstractService<Visibilidade> {

    baseUrls: { obrigacoes: string, domicilios: string, certidoes: string } = {
        obrigacoes: environment.apiUrl + '/monitoramento/status-baixasPorConta/obrigacoes',
        domicilios: environment.apiUrl + '/monitoramento/status-baixasPorConta/domicilios',
        certidoes: environment.apiUrl + '/monitoramento/status-baixasPorConta/certidoes'
    };

    constructor(http: HttpClient) {
        super(http, '/administracao/visibilidades', Visibilidade);
    }


    getBaixa(table: 'obrigacao' | 'domicilioEletronico' | 'certidao', id: string): any {
        return this.http.get(`${environment.apiUrl}/monitoramento/status-baixas/retorna-baixa/${table}/${id}`);
    }

    alterar(id: string, dados: any, table: 'obrigacao' | 'domicilioEletronico' | 'certidao') {
        if (dados.manutencaoDataInicio) {
            dados.manutencaoDataInicio = Helpers.formatDateDb(dados.manutencaoDataInicio);
        }

        if (dados.manutencaoDataFim) {
            dados.manutencaoDataFim = Helpers.formatDateDb(dados.manutencaoDataFim);
        }
        return this.http.put(`${environment.apiUrl}/monitoramento/status-baixas/alterar-registros/${table}/${id}`, dados);
    }
}
