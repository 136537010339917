<nz-page-header class="site-page-header" nzTitle="Detalhe da Conta" nzSubtitle="Informações cadastrais da conta">
</nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>

    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>

    <div nz-row [nzGutter]="24" *ngIf="!carregando">

        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card>

                <div nz-row>
                    <nz-space [nzSize]="24">
                        <img *nzSpaceItem nz-image class="imagem-avatar" [nzSrc]="conta?.srcAvatar"
                             [nzFallback]="fallbackAvatar"
                             alt="{{conta.identificador}}">
                        <h2 *nzSpaceItem
                            style="font-size: 18px; padding-top: 15px;">{{ conta.nome }}</h2>
                        <!--                        <nz-card-meta *nzSpaceItem  [nzTitle]="conta.nome + ' ('+ conta.identificador + ')'"></nz-card-meta>-->
                    </nz-space>
                </div>

                <nz-divider></nz-divider>

                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="ID">
                            <nz-list-item-meta-title>
                                {{ conta.id }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>

                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Status">
                            <nz-list-item-meta-title>
                                {{ conta.ativo ? 'Ativo' : 'Inativo' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>

                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{ conta.created_at ? (conta.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{ conta.updated_at ? (conta.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <br/>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18" style="padding-bottom: 400px;">

            <nz-card id="informacoes-pessoais" nzTitle="Informações da Conta" [nzExtra]="extraInfPessoais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Identificador">
                            <nz-list-item-meta-title>
                                {{ conta.identificador ? conta.identificador : '-' }}
                                <i nz-typography class="fa fa-external-link"
                                   [ngStyle]="infraestrutura.dominioStatus === 2 ? {'color': '#1890ff',
                                   'cursor': 'pointer'} : {'color' : '#c2c2c2'}"
                                   [title]="infraestrutura.dominioStatus === 2 ? conta.identificador + '.mastertax.app/' : ''"
                                   (click)="link(conta);"
                                ></i>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Nome">
                            <nz-list-item-meta-title>
                                {{ conta.nome }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Tipo">
                            <nz-list-item-meta-title>
                                {{ conta.tipo ? conta.tipo : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Plano">
                            <nz-list-item-meta-title>
                                <a (click)="conta.plano_id && openTabPlano(
                                   '/administracao/planos/plano-detalhe',
                                   conta.plano_id,
                                   {id: conta.plano_id, plano_descricao: conta?.plano_descricao}
                                   )">
                                    {{ conta?.plano_descricao ? conta?.plano_descricao : '-' }}
                                    <i class="fa fa-external-link"></i>
                                </a>


                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Status">
                            <nz-list-item-meta-title>
                                {{ conta.ativo ? 'Ativo' : 'Inativo' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Em Implantação">
                            <nz-list-item-meta-title>
                                {{ conta.implantacao ? 'Sim' : 'Não' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Em Manutenção">
                            <nz-list-item-meta-title>
                                {{ conta.manutencao ? 'Sim' : 'Não' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Classificação">
                            <nz-list-item-meta-title>
                                {{ conta.classificacao ? labelClassificacao[conta.classificacao] : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Contratado">
                            <nz-list-item-meta-title>
                                {{ conta.contratado ? labelContradado[conta.contratado] : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Nome do Responsável">
                            <nz-list-item-meta-title>
                                {{ conta.responsavelNome ? conta.responsavelNome : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Documento do Responsável">
                            <nz-list-item-meta-title>
                                {{ conta.responsavelDocumento ? (conta.responsavelDocumento | cpfCnpj) : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                </nz-list>
            </nz-card>
            <ng-template #extraInfPessoais>
                <button
                        nz-button
                        nzType="primary"
                        (click)="editar()"
                        [nzLoading]="loadings.modalAlterar"
                        *ngIf="hasPermissao(screenPermissoes.infos.alterarInformacoes, currentPermissoes)"
                >Alterar
                </button>
            </ng-template>

            <ng-template #extraAgrupamento>
            </ng-template>

            <ng-template #extraInfra>
                <small style="margin-right: 10px;">Última verificação
                    em: {{ infraestrutura.updated_at | date: 'dd/MM/YY - HH:mm:ss' }}</small>
                <button nz-button nzType="primary"
                        (click)="conferirInfra()"
                        [nzLoading]="loadings.conferindoConfigs"
                        *ngIf="hasPermissao(screenPermissoes.infra.verificarInfra, currentPermissoes)">
                    Verificar
                </button>
            </ng-template>

            <nz-card id="infraestrutura" nzTitle="Infraestrutura" [nzExtra]="extraInfra"
                     *ngIf="hasPermissao(screenPermissoes.infra.card, currentPermissoes)">


                <nz-list>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.solicitarInfra, currentPermissoes)">

                        <nz-list-item-meta nzDescription="Solicitar Configuração">
                            <nz-list-item-meta-title>
                                Enviar email de solicitação de configuração para usuários "Infraestrutura".
                            </nz-list-item-meta-title>
                            <nz-list-item-meta-description *ngIf="infraestrutura?.ultimaSolicitacaoData">
                                Última solicitação enviada
                                em {{ infraestrutura?.ultimaSolicitacaoData | date: 'dd/MM/yy - HH:mm' }}
                                por {{ infraestrutura?.ultimaSolicitacaoUsuarioNome }}

                            </nz-list-item-meta-description>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary"
                                        (click)="showConfirmSolicitarInfra()"
                                        [nzLoading]="loadings.solicitandoInfra">Solicitar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>


                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.reportarInfra, currentPermissoes)">

                        <nz-list-item-meta nzDescription="Reportar Infraestrutura Completa">
                            <nz-list-item-meta-title>
                                Enviar email ao solicitande do pedido reportando Infraestrutura Completa.
                            </nz-list-item-meta-title>
                            <nz-list-item-meta-description *ngIf="infraestrutura?.ultimaReportagemData">
                                Último e-mail enviado
                                em {{ infraestrutura?.ultimaReportagemData | date: 'dd/MM/yy - HH:mm' }}
                                por {{ infraestrutura?.ultimaReportagemUsuarioNome }}
                            </nz-list-item-meta-description>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary"
                                        (click)="showConfirmReportarInfra()"
                                        [nzLoading]="loadings.reportandoInfra">Reportar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                </nz-list>

                <nz-divider nzPlain [nzText]="extraConfiguracoes" nzOrientation="left"></nz-divider>
                <ng-template #extraConfiguracoes><b>Configurações</b></ng-template>

                <nz-list>
                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.accountManager, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Account Manager">
                            <nz-list-item-meta-title>
                                {{ infraestrutura.servidorConta_descricao }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.alterarCredsBanco, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Tipo de Infraestrutura">
                            <nz-list-item-meta-title>
                                {{ infraestrutura.tipoInfraestrutura ? strTipoInfra[infraestrutura.tipoInfraestrutura] : 'Tipo de infraestrutura contratada.' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarInfraestrutura()"
                                        [nzLoading]="loadings.modalAlterarInfra"
                                        *ngIf="hasPermissao(screenPermissoes.infra.alterarCredsBanco, currentPermissoes)"
                                >Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.alterarCredsBanco, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Banco de dados">
                            <nz-list-item-meta-title>
                                Credenciais do banco de dados vinculado à esta conta
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarCredenciaisBD()"
                                        [nzLoading]="loadings.modalAlterarCreds"
                                        *ngIf="hasPermissao(screenPermissoes.infra.alterarCredsBanco, currentPermissoes)"
                                >Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.alterarServidorRobos, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Servidor dos Robôs do Embedded">
                            <nz-list-item-meta-title>
                                {{infraestrutura?.servidorRobosEmbedded ?? '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarServidorRobosEmbedded()"
                                        [nzLoading]="loadings.btnAlterarServidorRobos"
                                        *ngIf="hasPermissao(screenPermissoes.infra.alterarServidorRobos, currentPermissoes)"
                                >Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                </nz-list>

                <nz-divider nzPlain [nzText]="extraServicos" nzOrientation="left"></nz-divider>
                <ng-template #extraServicos><b>Serviços</b></ng-template>
                <nz-list>
                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.gerarDominio, currentPermissoes)">

                        <nz-list-item-meta nzDescription="Status Domínio">
                            <nz-list-item-meta-title style="font-weight: bold">
                                <span [ngStyle]="{color: corStatus[infraestrutura.dominioStatus], 'font-weight': 'bold'}">
                                {{ descricaoStatus[infraestrutura.dominioStatus] }}
                                </span>
                                <small [ngStyle]="{color: corStatus[infraestrutura.dominioStatus]}"><br>
                                    {{ infraestrutura.dominiodata ? (infraestrutura.dominiodata | date: 'dd/MM/yy - HH:mm:ss') : '' }}
                                </small>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="link"
                                        (click)="onLogInfra('dominioStatus')"
                                        [nzLoading]="loadings.dominioStatus"
                                        [disabled]="!infraestrutura.dominioLog">Log
                                </button>
                                <nz-divider [nzType]="'vertical'"
                                            *ngIf="infraestrutura.dominioStatus !== 2 && !loadings.conferindoConfigs
                                                    && hasPermissao(screenPermissoes.infra.gerarDominio, currentPermissoes)">
                                </nz-divider>
                                <button nz-button nzType="primary"
                                        (click)="onGerar('dominio')"
                                        [nzLoading]="loadings.gerarDominio"
                                        *ngIf="infraestrutura.dominioStatus !== 2 && !loadings.conferindoConfigs
                                        && hasPermissao(screenPermissoes.infra.gerarDominio, currentPermissoes)"
                                >Gerar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.gerarBd, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Status Banco de dados">
                            <nz-list-item-meta-title style="font-weight: bold">
                                <span [ngStyle]="{color: corStatus[infraestrutura.databaseStatus], 'font-weight': 'bold'}">
                                {{ descricaoStatus[infraestrutura.databaseStatus] }}
                                </span>
                                <small [ngStyle]="{color: corStatus[infraestrutura.databaseStatus]}"><br>
                                    {{ infraestrutura.databaseStatusData ? (infraestrutura.databaseStatusData | date: 'dd/MM/yy - HH:mm:ss') : '' }}
                                </small>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="link"
                                        (click)="onLogInfra('databaseStatus')"
                                        [nzLoading]="loadings.databaseStatus"
                                        [disabled]="!infraestrutura.databaseStatusLog">Log
                                </button>
                                <nz-divider [nzType]="'vertical'"
                                            *ngIf="infraestrutura.databaseStatus !== 2 && !loadings.conferindoConfigs
                                                    && hasPermissao(screenPermissoes.infra.gerarBd, currentPermissoes)">
                                </nz-divider>
                                <button nz-button nzType="primary"
                                        (click)="onGerar('database')"
                                        [nzLoading]="loadings.gerarDatabase"
                                        *ngIf="infraestrutura.databaseStatus !== 2 && !loadings.conferindoConfigs
"
                                >Gerar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.gerarS3, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Status S3">
                            <nz-list-item-meta-title>
                                <span [ngStyle]="{color: corStatus[infraestrutura.s3Status], 'font-weight': 'bold'}">
                                {{ descricaoStatus[infraestrutura.s3Status] }}
                                </span>
                                <small [ngStyle]="{color: corStatus[infraestrutura.s3Status]}"><br>
                                    {{ infraestrutura.s3StatusData ? (infraestrutura.s3StatusData | date: 'dd/MM/yy - HH:mm:ss') : '' }}
                                </small>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="link"
                                        (click)="onLogInfra('s3Status')"
                                        [nzLoading]="loadings.s3Status"
                                        [disabled]="!infraestrutura.s3StatusLog">Log
                                </button>
                                <nz-divider [nzType]="'vertical'"
                                            *ngIf="infraestrutura.s3Status !== 2 && !loadings.conferindoConfigs
                                                    && hasPermissao(screenPermissoes.infra.gerarS3, currentPermissoes)">
                                </nz-divider>
                                <button nz-button nzType="primary"
                                        (click)="onGerar('s3')"
                                        [nzLoading]="loadings.gerarS3"
                                        *ngIf="infraestrutura.s3Status !== 2 && !loadings.conferindoConfigs
                                        && hasPermissao(screenPermissoes.infra.gerarS3, currentPermissoes)"
                                >Gerar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item *ngIf="hasPermissao(screenPermissoes.infra.gerarEmbedded, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Status Embedded">
                            <nz-list-item-meta-title>
                                <span [ngStyle]="{color: corStatus[infraestrutura.embeddedStatus], 'font-weight': 'bold'}">
                                {{ descricaoStatus[infraestrutura.embeddedStatus] }}
                                </span>
                                <small [ngStyle]="{color: corStatus[infraestrutura.embeddedStatus]}"><br>
                                    {{ infraestrutura.embeddedStatusData ? (infraestrutura.embeddedStatusData | date: 'dd/MM/yy - HH:mm:ss') : '' }}
                                </small>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="link"
                                        (click)="onLogInfra('embeddedStatus')"
                                        [nzLoading]="loadings.embeddedStatus"
                                        [disabled]="!infraestrutura.embeddedStatusLog">Log
                                </button>
                                <nz-divider [nzType]="'vertical'"
                                            *ngIf="infraestrutura.embeddedStatus !== 2 && !loadings.conferindoConfigs
                                                    && hasPermissao(screenPermissoes.infra.gerarEmbedded, currentPermissoes)">
                                </nz-divider>

                                <button nz-button nzType="primary"
                                        (click)="onGerar('embedded')"
                                        [nzLoading]="loadings.gerarEmbedded"
                                        *ngIf="infraestrutura.databaseStatus === 2 && !loadings.conferindoConfigs && infraestrutura.embeddedStatus !== 2"
                                >Gerar
                                </button>
                            </nz-list-item-action>

                        </ul>
                    </nz-list-item>

                </nz-list>

                <nz-divider nzPlain [nzText]="extraDemais" nzOrientation="left"></nz-divider>
                <ng-template #extraDemais><b>Demais</b></ng-template>
                <nz-list>
                    <nz-list-item
                            *ngIf="hasPermissao(screenPermissoes.infra.alterarCredsBanco, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Elastic Search">
                            <nz-list-item-meta-title>
                                Elastic Search vinculado à esta conta
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="modalElasticSearch(true)"
                                        [nzLoading]="loadings.modalAlterarCreds"
                                        *ngIf="hasPermissao(screenPermissoes.infra.alterarCredsBanco, currentPermissoes)"
                                >Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>


                </nz-list>
            </nz-card>

            <nz-card id="agrupamento" nzTitle="Agrupamento" [nzExtra]="extraAgrupamento"
                     *ngIf="hasPermissao(screenPermissoes.agrupamento.card, currentPermissoes)">
                <nz-list>
                    <nz-list-item
                            *ngIf="hasPermissao(screenPermissoes.agrupamento.visibilidade, currentPermissoes)">
                        <nz-list-item-meta nzDescription="Visilidade">
                            <nz-list-item-meta-title>
                                Visibilidade
                            </nz-list-item-meta-title>
                            <nz-list-item-meta-description>
                                {{ conta.visibilidade_descricao ? conta.visibilidade_descricao : 'Nenhuma visibilidade vinculada à Conta' }}
                            </nz-list-item-meta-description>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarVisibilidade()"
                                        [nzLoading]="loadings.alterarVisibilidade"
                                        *ngIf="hasPermissao(screenPermissoes.agrupamento.visibilidade, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                </nz-list>
            </nz-card>

            <nz-card id="configuracoes" nzTitle="Configurações Gerais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Avatar">
                            <nz-list-item-meta-title>
                                <img nz-image class="imagem-avatar" [nzSrc]="conta.srcAvatar"
                                     [nzFallback]="fallbackAvatar" alt="{{conta.identificador}}">
                                {{ conta?.srcAvatar ? '' : 'Nenhum avatar associado à conta' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="modalAlterarAvatar(true)"
                                        [nzLoading]="loadings.alterandoAvatar"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.avatar, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Logotipo">
                            <nz-list-item-meta-title>

                                <img nz-image class="imagem-logotipo" [nzSrc]="conta.srcLogo"
                                     [nzFallback]="fallbackAvatar" alt="{{conta.identificador}}">
                                {{ conta?.srcLogo ? '' : 'Nenhum Logotipo associado à conta' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="modalAlterarLogo(true)"
                                        [nzLoading]="loadings.alterandoAvatar"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.logotipo, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Menu personalizado">
                            <nz-list-item-meta-title>
                                {{ conta.menuMerge ? 'Sim' : 'Não' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarMenuMerge()"
                                        [nzLoading]="loadings.alterarMenuMerge"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.menuPersonalizado, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Movidesk Token">
                            <nz-list-item-meta-title>
                                {{ infraestrutura.movideskKey ? infraestrutura.movideskKey : 'Padrão' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarMovidesk()"
                                        [nzLoading]="loadings.modalAlterarCreds"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.movideskToken, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="UserGuiding">
                            <nz-list-item-meta-title>
                                Habilita funcionalidade UserGuiding
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="infraestrutura.userGuiding"
                                           (ngModelChange)="alterarUserGuiding()" nzCheckedChildren="Sim"
                                           nzUnCheckedChildren="Não" [nzLoading]="loadings.alterarUserGuiding"
                                           *ngIf="hasPermissao(screenPermissoes.configuracoes.userGuiding, currentPermissoes)"></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Domínio(s)">
                            <nz-list-item-meta-title>
                                <nz-tag *ngFor="let dominio of infraestrutura.dominios">{{ dominio }}</nz-tag>
                                <span *ngIf="!infraestrutura.dominios || !infraestrutura.dominios.length">Nenhum domínio cadastrado para conta.</span>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarDominios()"
                                        [nzLoading]="loadings.modalAlterarDominios"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.dominios, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Identificador(es)">
                            <nz-list-item-meta-title>
                                <span *ngFor="let identificador of infraestrutura.identificadores">
                                    {{ identificador }}
                                    <i nz-typography class="fa fa-external-link"
                                       [ngStyle]="infraestrutura.dominioStatus === 2 ? {'color': '#1890ff',
                                   'cursor': 'pointer'} : {'color' : '#c2c2c2'}"
                                       [title]="infraestrutura.dominioStatus === 2 ? identificador + '.mastertax.app/' : ''"
                                       (click)="link({identificador: identificador});"
                                    ></i>
                                </span>
                                <span *ngIf="!infraestrutura.identificadores || !infraestrutura.identificadores.length">Nenhum identificador cadastrado para conta.</span>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarIdentificadores()"
                                        [nzLoading]="loadings.modalAlterarIdentificadores"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.identificadores, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Limite de Usuários">
                            <nz-list-item-meta-title>
                                {{ conta.limiteUsuarios ? conta.limiteUsuarios : 'Ilimitado' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarLimiteUsuarios()"
                                        [nzLoading]="loadings.modalAlterarLimiteUsuario"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.limiteEmpresa, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>


                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Limite de Empresas">
                            <nz-list-item-meta-title>
                                {{ conta.limiteEmpresas ? conta.limiteEmpresas : 'Ilimitado' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarLimiteEmpresa()"
                                        [nzLoading]="loadings.modalAlterarLimiteEmpresa"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.limiteEmpresa, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Login MFA">
                            <nz-list-item-meta-title>
                                <span *ngIf="metodoMFADescricao === 'desativado'">
                                    Desativado
                                </span>
                                <span *ngIf="metodoMFADescricao === 'obrigatorioTodos'">
                                    Obrigatório para todos os usuários
                                </span>
                                <span *ngIf="metodoMFADescricao === 'obrigatorioAdmin'">
                                    Obrigatório para todos os administradores
                                </span>
                                <span *ngIf="metodoMFADescricao === 'opcional'">
                                    Opcional para cada usuário
                                </span>
                                <span *ngIf="metodoMFADescricao === ''">
                                    Regra MFA não definida
                                </span>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="modalEditarMFA(true)"
                                        [nzLoading]="loadings.modalAlterarMFA"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.limiteEmpresa, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Dado obrigatório para login">
                            <nz-list-item-meta-title>
                                {{ arrayDadosObrigatorios[conta?.campoLogin] ? arrayDadosObrigatorios[conta?.campoLogin] : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="onEditarCampoLogin()"
                                        [nzLoading]="loadings.modalAlterarCampoLogin"
                                        *ngIf="hasPermissao(screenPermissoes.configuracoes.campoLogin, currentPermissoes)">
                                    Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Login SSO">
                            <nz-list-item-meta-title>
                                Habilita funcionalidade Login por SSO
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="infraestrutura.loginSSO"
                                           (ngModelChange)="alterarLoginSSO()" nzCheckedChildren="Sim"
                                           nzUnCheckedChildren="Não" [nzLoading]="loadings.alterarLoginSSO"
                                           *ngIf="hasPermissao(screenPermissoes.configuracoes.loginSSO, currentPermissoes)"></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>


                </nz-list>
            </nz-card>

            <nz-card id="modulos" nzTitle="Módulos" [nzLoading]="loadings.tableModulos" [nzExtra]="extraModulos">
                <nz-table #basicTable
                          [nzData]="items"
                          [nzTotal]="pagination?.total"
                          [nzPageSize]="pagination?.per_page"
                          [nzPageIndex]="pagination?.current_page"
                          (nzQueryParams)="queryTableModulos($event)"
                          class="fonte-doze table-small"
                          [nzShowTotal]="rangeTemplate"
                          [nzSize]="'small'">
                    <thead>
                    <tr>
                        <th id="check-all">
                        </th>
                        <th nzColumnKey="identificador" [nzSortFn]="true" nzAlign="left">Identificador</th>
                        <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Descrição</th>
                        <th nzColumnKey="limite" [nzSortFn]="true" nzAlign="center">Limite</th>
                        <th nzColumnKey="unidade" [nzSortFn]="true" nzAlign="center">Unidade</th>
                        <th nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competencia Inicial</th>
                        <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Cadastro Data</th>
                        <th [ngStyle]="{'width' : '80px'}"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable.data">
                        <td nzLeft>
                        </td>
                        <td> {{ item?.identificador }}</td>
                        <td style="word-break: break-word" nzAlign="center">{{ item?.descricao }}</td>
                        <td nzAlign="center">{{ item?.limite ? item.limite : '0' }}</td>
                        <td nzAlign="center">{{ item?.unidade ? item.unidade : '-' }}</td>
                        <td nzAlign="center">{{ (item?.competencia && item?.controleCompetencia == 1) ? (item.competencia | date:'MM/YYYY') : '-'}}</td>
                        <td nzAlign="center">{{ item?.created_at | date:'dd/MM/YY - HH:mm:ss' }}</td>
                        <td nzAlign="right" style="white-space: nowrap;">
                            <button nz-button
                                    (click)="onEditarModulo(item?.id);"
                                    nzType="link"
                                    *ngIf="hasPermissao(screenPermissoes.modulos.editar, currentPermissoes)">
                                Editar
                            </button>
                            <nz-divider nzType="vertical"></nz-divider>
                            <button nz-button
                                    (click)="showConfirmRemoverModulo(item?.id)"
                                    nzType="link"
                                    *ngIf="hasPermissao(screenPermissoes.modulos.remover, currentPermissoes)">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplate let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>
            </nz-card>
            <ng-template #extraModulos>
                <button nz-button nzType="primary" (click)="onCarregarAllModulos()"
                        [nzLoading]="loadings.adicionarModulos"
                        *ngIf="hasPermissao(screenPermissoes.modulos.adicionar, currentPermissoes)">
                    Adicionar
                </button>
            </ng-template>

            <ng-template #extraCreditos>
                <button nz-button nzType="primary" (click)="modalAdicionarCreditos(true)"
                        [nzLoading]="loadings.adicionarModulos"
                        *ngIf="hasPermissao(screenPermissoes.creditos.adicionar, currentPermissoes)">
                    Adicionar
                </button>
            </ng-template>

            <nz-card id="creditos" nzTitle="Créditos" [nzLoading]="loadings.tableCreditos" [nzExtra]="extraCreditos">
                <nz-table #basicTableCreditos
                          [nzData]="dados.creditos"
                          [nzTotal]="paginations.creditos?.total"
                          [nzPageSize]="paginations.creditos?.per_page"
                          [nzPageIndex]="paginations.creditos?.current_page"
                          (nzQueryParams)="queryTableCreditos($event)"
                          class="fonte-doze table-small"
                          [nzShowTotal]="rangeTemplateCreditos"
                          [nzSize]="'small'">
                    <thead>
                    <tr>
                        <th></th>
                        <th nzColumnKey="contexto" [nzSortFn]="true" nzAlign="left">Contexto</th>
                        <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Descrição</th>
                        <th nzColumnKey="saldo" [nzSortFn]="true" nzAlign="center">Saldo</th>
                        <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Cadastro Data</th>
                        <th [ngStyle]="{'width' : '80px'}"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTableCreditos.data">
                        <td nzLeft>
                        </td>
                        <td> {{ item?.contexto }}</td>
                        <td style="word-break: break-word" nzAlign="center">{{ item?.descricao }}</td>
                        <td nzAlign="center">{{ item?.saldo ? item.saldo : '0' }}</td>
                        <td nzAlign="center">{{ item?.created_at | date:'dd/MM/YY - HH:mm:ss' }}</td>
                        <td nzAlign="right" style="white-space: nowrap;">
                            <button nz-button
                                    (click)="modalEditarCredito(true, item?.id);"
                                    nzType="link"
                                    *ngIf="hasPermissao(screenPermissoes.creditos.editar, currentPermissoes)">
                                Editar
                            </button>
                            <nz-divider nzType="vertical"></nz-divider>
                            <button nz-button
                                    (click)="showConfirmRemoverCredito(item?.id)"
                                    nzType="link"
                                    *ngIf="hasPermissao(screenPermissoes.creditos.remover, currentPermissoes)">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplateCreditos let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>
            </nz-card>


        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formEditar.modalVisible" [nzTitle]="'Alterar Dados Da Conta'" [nzClosable]="true"
          (nzOnCancel)="fechar(formEditar)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Identificador</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input [disabled]="true" value="{{conta.identificador}}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Nome da Conta">
                    <input nz-input type="text" formControlName="nome" size="60"/>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option
                                [nzLabel]="'Empresarial'" [nzValue]="'Empresarial'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Contabilidade'" [nzValue]="'Contabilidade'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Plano</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Nome da Conta">
                    <nz-select formControlName="plano_id" [nzPlaceHolder]="'Nenhum'"
                               nzAllowClear nzShowSearch>
                        <nz-option *ngFor="let opt of planoOptions"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Status</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="ativo" style="width: 100%;">
                        <nz-option [nzLabel]="'Ativo'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Inativo'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em Implantação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="implantacao" style="width: 100%;">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em Manutenção</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="manutencao" style="width: 100%;">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Classificação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="classificacao" style="width: 100%;">
                        <nz-option [nzLabel]="'Sem classificação'" [nzValue]="'semClassificacao'"></nz-option>
                        <nz-option [nzLabel]="'Prime'" [nzValue]="'prime'"></nz-option>
                        <nz-option [nzLabel]="'Super'" [nzValue]="'super'"></nz-option>
                        <nz-option [nzLabel]="'Excellence'" [nzValue]="'excellence'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5">Contratado</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="contratado">
                        <nz-option *ngFor="let e of empresaContratada" nzLabel="{{e.nome + ' - ' + (e.cnpj | cpfCnpj)}}"
                                   [nzValue]="e.cnpj"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-divider nzPlain nzText="Responsável" nzOrientation="center"></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="responsavelNome" placeholder="Nome do Responsável">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Documento</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="responsavelDocumento"
                           placeholder="Documento do Responsável"
                           (keydown)="verificaMascaraDoc($event)"
                           (keyup)="verificaMascaraDocBack($event)"
                           autocomplete="off" mask="{{maskDocFilter}}">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formEditar)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formEditar)">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCredenciaisBanco.modalVisible" [nzTitle]="'Alterar Credenciais do Banco'"
          [nzClosable]="true"
          (nzOnCancel)="modalCredenciaisBanco(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formCredenciaisBanco.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Banco de Dados</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" [disabled]="true" value="{{infraestrutura.bd_database}}">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Servidor</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="servidor_id"
                               nzShowArrow="false">
                        <nz-option nzCustomContent *ngFor="let opt of listOptsServidores" [nzLabel]="opt.servidor"
                                   [nzValue]="opt.id">
                            {{ opt.servidor }} <br><small> {{ opt.host }}</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <!--            <nz-form-item>-->
            <!--                <nz-form-label [nzSpan]="5" nzRequired>Usuário</nz-form-label>-->
            <!--                <nz-form-control [nzSpan]="14">-->
            <!--                    <input nz-input type="text" formControlName="bd_username">-->
            <!--                </nz-form-control>-->
            <!--            </nz-form-item>-->
            <!--            <nz-form-item>-->
            <!--                <nz-form-label [nzSpan]="5" nzRequired>Senha</nz-form-label>-->
            <!--                <nz-form-control [nzSpan]="14">-->
            <!--                    <nz-input-group [nzSuffix]="suffixSenha">-->
            <!--                        <input [type]="senhaVisible ? 'text' : 'password'"-->
            <!--                               nz-input-->
            <!--                               formControlName="bd_password"-->
            <!--                               size="60">-->
            <!--                    </nz-input-group>-->
            <!--                </nz-form-control>-->
            <!--                <ng-template #suffixSenha>-->
            <!--                    <i nz-icon [nzType]="senhaVisible ? 'eye-invisible' : 'eye'"-->
            <!--                       (click)="senhaVisible = !senhaVisible"></i>-->
            <!--                </ng-template>-->
            <!--            </nz-form-item>-->


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCredenciaisBanco(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarCreds" (click)="confirmaEditarCreds()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarVisibilidade.modalVisible" [nzTitle]="'Alterar Visibilidade da Conta'"
          [nzClosable]="true"
          (nzOnCancel)="modalVisibilidade(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarVisibilidade.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="visibilidade_id" [nzPlaceHolder]="'Selecione'" nzAllowClear>
                        <nz-option *ngFor="let opt of visibilidadeOptions"
                                   [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalVisibilidade(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarVisibilidade"
                (click)="confirmaEditarVisibilidade()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarMenuMerge.modalVisible" [nzTitle]="'Alterar Menu Personalizado'"
          [nzClosable]="true"
          (nzOnCancel)="modalMenuMerge(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarMenuMerge.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Menu Personalizado</nz-form-label>
                <nz-form-control [nzSpan]="10">
                    <nz-select formControlName="menuMerge" [nzPlaceHolder]="'Selecione'">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalMenuMerge(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarMenuTipo" (click)="confirmaEditarMenuMerge()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarPlano.modalVisible" [nzTitle]="'Alterar Plano'"
          [nzClosable]="true"
          (nzOnCancel)="modalPlano(false)" [nzWidth]="648">

    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalPlano"></nz-skeleton>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarPlano.formGroup"
              *ngIf="!loadings.modalPlano">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o plano para ser vinculado à esta conta.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzShowSearch formControlName="plano_id" [nzPlaceHolder]="'Nenhum'"
                               nzAllowClear>
                        <nz-option *ngFor="let opt of planoOptions"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalPlano(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.plano" (click)="confirmaVincularPlano()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarModulo.modalVisible" [nzTitle]="'Editar Módulo'"
          [nzClosable]="true"
          (nzOnCancel)="modaleditarModulo(false)" [nzWidth]="648">

    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalAlterarModulo"></nz-skeleton>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarModulo.formGroup"
              *ngIf="!loadings.modalAlterarModulo">
            <input nz-input type="hidden" formControlName="id">
            <nz-form-item>
                <nz-form-label [nzSpan]="7">Identificador</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input type="text" formControlName="identificador">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7">Descricao</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input type="text" formControlName="descricao">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Limite</nz-form-label>
                <nz-form-control nzErrorTip="Informe o limite" [nzSpan]="12">
                    <input nz-input formControlName="limite" (keydown)="keyPressNumber($event)">
                </nz-form-control>
                <nz-form-label [nzSpan]="4">
                    <span style="color: #656565 !important;">{{ unidadeSelecionada }}</span>
                </nz-form-label>
            </nz-form-item>

            <nz-form-item *ngIf="controleCompetencia">
                <nz-form-label [nzSpan]="7" nzRequired>Competência inicial</nz-form-label>
                <nz-form-control nzErrorTip="Informe a competencia" [nzSpan]="12">
                    <nz-date-picker formControlName="competencia" nzFormat="MM/YYYY"
                                    nzMode="month"
                                    nzPlaceHolder="MM/AAAA" style="width: 100%"
                    ></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modaleditarModulo(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.plano" (click)="confirmaEditarModulo()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarCredito.modalVisible" [nzTitle]="'Editar Crédito'"
          [nzClosable]="true"
          (nzOnCancel)="modalEditarCredito(false)" [nzWidth]="648">

    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalAlterarCredito"></nz-skeleton>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarCredito.formGroup"
              *ngIf="!loadings.modalAlterarCredito">
            <input nz-input type="hidden" formControlName="id">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Contexto</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="contexto">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descricao</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="descricao">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Saldo</nz-form-label>
                <nz-form-control nzErrorTip="Informe o saldo" [nzSpan]="14">
                    <input nz-input formControlName="saldo" type="number">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modaleditarModulo(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.botaoAlterarCredito" (click)="confirmaEditarCredito()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAdicionarModulo.modalVisible" [nzTitle]="'Adicionar módulo'"
          [nzClosable]="true"
          (nzOnCancel)="modalListaModulos(false)" [nzWidth]="648">

    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.adicionarModulos"></nz-skeleton>

        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAdicionarModulo.formGroup"
              *ngIf="!loadings.adicionarModulos">
            <nz-form-item>
                <nz-form-label [nzSpan]="7">Módulo</nz-form-label>
                <nz-form-control nzErrorTip="Informe o módulo" [nzSpan]="12">
                    <nz-select formControlName="modulo_id" [nzPlaceHolder]="'Nenhum'"
                               nzAllowClear (ngModelChange)="chancheUnidade('adicionar')">
                        <nz-option *ngFor="let opt of modulosOptions"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Limite</nz-form-label>
                <nz-form-control nzErrorTip="Informe o limite" [nzSpan]="12">
                    <input nz-input formControlName="limite" (keydown)="keyPressNumber($event)">
                </nz-form-control>
                <nz-form-label [nzSpan]="4">
                    <span style="color: #656565 !important;">{{ unidadeSelecionada }}</span>
                </nz-form-label>
            </nz-form-item>
            <nz-form-item *ngIf="controleCompetencia">
                <nz-form-label [nzSpan]="7" nzRequired>Competência inicial</nz-form-label>
                <nz-form-control nzErrorTip="Informe a competencia" [nzSpan]="12">
                    <nz-date-picker formControlName="competencia" nzFormat="MM/YYYY"
                                    nzMode="month"
                                    nzPlaceHolder="MM/AAAA" style="width: 100%"
                    ></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalListaModulos(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.adicionarModulos" (click)="consfirmarInserirModulo()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAdicionarCredito.modalVisible" [nzTitle]="'Adicionar crédito'"
          [nzClosable]="true"
          (nzOnCancel)="modalAdicionarCreditos(false)" [nzWidth]="648">

    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalAdicionarCreditos"></nz-skeleton>

        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAdicionarCredito.formGroup"
              *ngIf="!loadings.modalAdicionarCreditos">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Crédito</nz-form-label>
                <nz-form-control nzErrorTip="Informe o crédito" [nzSpan]="14">
                    <nz-select formControlName="credito_id" [nzPlaceHolder]="'Selecione um contexto.'">
                        <nz-option *ngFor="let opt of listOptsCreditos"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Saldo Inicial</nz-form-label>
                <nz-form-control nzErrorTip="Informe o saldo inicial" [nzSpan]="14">
                    <input nz-input formControlName="saldo" type="number">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAdicionarCreditos(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.adicionarCreditos"
                (click)="consfirmarInserirCredito()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAlterarAvatarVisible" [nzTitle]="'Alterar Avatar'"
          [nzClosable]="true"
          (nzOnCancel)="modalAlterarAvatar(false)" [nzWidth]="648">

    <div nz-row *nzModalContent style="text-align: center;">

        <nz-form-label [nzSpan]="24">Selecione uma imagem (.jpeg/.png) para upload.</nz-form-label>

        <nz-form-item>
            <nz-form-control [nzSpan]="24">
                <input type="file" (change)="fileChangeEventAvatar($event)" accept=".png, .jpg, .jpeg">
            </nz-form-control>
        </nz-form-item>
        <image-cropper
                [imageChangedEvent]="imageChangedEventAvatar"
                [maintainAspectRatio]="true"
                [aspectRatio]="4 / 4"
                format="png"
                (imageCropped)="imageCroppedAvatar($event)"
                [containWithinAspectRatio]="true"
                [roundCropper]="true"
                [resizeToWidth]="150"
                style="width: 60%; margin-left: 20%;"
        ></image-cropper>
    </div>
    <div *nzModalFooter>
        <button class="new-markup-bnt" nz-button nzType="link"
                (click)="showConfirmRemoverAvatar('avatar')">
            <em nz-icon nzType="close-circle" style="color: red"></em><span style="color: red">Remover avatar</span>
        </button>
        <button nz-button nzType="link" (click)="modalAlterarAvatar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterandoAvatar"
                (click)="confirmarAlterarImagem('avatar')">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAlterarLogoVisible" [nzTitle]="'Alterar Logotipo'"
          [nzClosable]="true"
          (nzOnCancel)="modalAlterarLogo(false)" [nzWidth]="648">
    <div nz-row *nzModalContent style="text-align: center;">
        <nz-form-label [nzSpan]="24">Selecione uma imagem (.jpeg/.png) para upload.</nz-form-label>
        <nz-form-item>
            <nz-form-control [nzSpan]="24">
                <input type="file" (change)="fileChangeEventLogo($event)" accept=".png, .jpg, .jpeg">
            </nz-form-control>
        </nz-form-item>
        <image-cropper
                [imageChangedEvent]="imageChangedEventLogo"
                [maintainAspectRatio]="false"
                [aspectRatio]="10 / 3"
                [containWithinAspectRatio]="true"
                format="png"
                (imageCropped)="imageCroppedLogo($event)"
                [resizeToWidth]="1000"
        ></image-cropper>
    </div>
    <div *nzModalFooter>
        <button class="new-markup-bnt" nz-button nzType="link"
                (click)="showConfirmRemoverAvatar('logotipo')">
            <em nz-icon nzType="close-circle" style="color: red"></em><span style="color: red">Remover Logotipo</span>
        </button>
        <button nz-button nzType="link" (click)="modalAlterarLogo(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterandoLogo"
                (click)="confirmarAlterarImagem('logotipo')">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formMovidesk.modalVisible" [nzTitle]="'Alterar Movidesk Token'"
          [nzClosable]="true"
          (nzOnCancel)="modalMovidesk(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formMovidesk.formGroup">

            <nz-form-item>
                <nz-form-control [nzSpan]="14" [nzOffset]="5">
                    <label nz-checkbox formControlName="padrao" [(ngModel)]="padraoChange">
                        <span>Token padrão</span>
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!padraoChange">
                <nz-form-label [nzSpan]="5">Token</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="movideskKey">
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalMovidesk(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarMovidesk" (click)="confirmaEditarMovidesk()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formLimiteEmpresa.modalVisible" [nzTitle]="'Alterar Limite Cadastral de Empresas'"
          [nzClosable]="true"
          (nzOnCancel)="modalLimiteEmpresa(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formLimiteEmpresa.formGroup">

            <nz-form-item>
                <nz-form-control [nzSpan]="14" [nzOffset]="5">
                    <label nz-checkbox formControlName="ilimitado" [(ngModel)]="ilimitadoEmpresaChange">
                        <span>Cadastro ilimitado</span>
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!ilimitadoEmpresaChange">
                <nz-form-label [nzSpan]="5">Limite</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="limiteEmpresas">
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalLimiteEmpresa(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarLimiteEmpresa"
                (click)="confirmaEditarLimiteEmpresa()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCampoLogin.modalVisible" [nzTitle]="'Alterar Campo Login'"
          [nzClosable]="true"
          (nzOnCancel)="modalCampoLogin(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formCampoLogin.formGroup">

            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Campo para Login</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="campoLogin">
                        <nz-option [nzLabel]="'Apelido'" [nzValue]="'apelido'"></nz-option>
                        <nz-option [nzLabel]="'CPF'" [nzValue]="'cpf'"></nz-option>
                        <nz-option [nzLabel]="'E-mail'" [nzValue]="'email'"></nz-option>
                    </nz-select>
                    <p nz-typography nzType="warning" style="margin-top: 10px;"
                       *ngIf="formCampoLogin.formGroup.value.campoLogin == 'cpf'">
                        Campo login deve obrigatóriamente ser o CPF. Ex: 371.***.***-02
                    </p>
                    <p nz-typography nzType="warning" style="margin-top: 10px;"
                       *ngIf="formCampoLogin.formGroup.value.campoLogin == 'email'">
                        Campo login deve obrigatóriamente ser o E-mail. CPF torna-se opcional. Ex:
                        yago-silv***@*********.com.br
                    </p>
                    <p nz-typography nzType="warning" style="margin-top: 10px;"
                       *ngIf="formCampoLogin.formGroup.value.campoLogin == 'apelido'">
                        Apelido: Campo login torna-se desbloqueado para vontade do usuário. Ex: yago.silveira
                    </p>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCampoLogin(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarCampoLogin"
                (click)="confirmaEditarCampoLogin()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formLimiteUsuario.modalVisible" [nzTitle]="'Alterar Limite Cadastral de Usuários'"
          [nzClosable]="true"
          (nzOnCancel)="modalLimiteUsuario(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formLimiteUsuario.formGroup">

            <nz-form-item>
                <nz-form-control [nzSpan]="14" [nzOffset]="5">
                    <label nz-checkbox formControlName="ilimitado" [(ngModel)]="ilimitadoUsuarioChange">
                        <span>Cadastro ilimitado</span>
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!ilimitadoUsuarioChange">
                <nz-form-label [nzSpan]="5">Limite</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="limiteUsuarios">
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalLimiteUsuario(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarLimiteEmpresa"
                (click)="confirmaEditarLimiteUsuario()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formDominios.modalVisible" [nzTitle]="'Alterar Domínios da Conta'"
          [nzClosable]="true"
          (nzOnCancel)="modalDominios(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDominios.formGroup">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Domínios</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select
                            nzMode="tags"
                            [nzTokenSeparators]="[',', ';']"
                            formControlName="dominios"
                            nzPlaceHolder="Domínio + [Enter]"
                    >
                        <nz-option *ngFor="let option of infraestrutura.dominios" [nzLabel]="option"
                                   [nzValue]="option"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalDominios(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarDominios" (click)="confirmaEditarDominios()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formIdentificadores.modalVisible" [nzTitle]="'Alterar Identificadores da Conta'"
          [nzClosable]="true"
          (nzOnCancel)="modalIdentificadores(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIdentificadores.formGroup">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Identificadores</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select
                            nzMode="tags"
                            [nzTokenSeparators]="[',', ';']"
                            formControlName="identificadores"
                            nzPlaceHolder="Identificador + [Enter]"
                    >
                        <nz-option *ngFor="let option of infraestrutura.identificadores" [nzLabel]="option"
                                   [nzValue]="option"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalIdentificadores(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarIdentificadores"
                (click)="confirmaEditarIdentificadores()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formConpass.modalVisible" [nzTitle]="'Alterar Conpass Ids'"
          [nzClosable]="true"
          (nzOnCancel)="modalConpass(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalAlterarConpass"></nz-skeleton>
        <form nz-form *ngIf="!loadings.modalAlterarConpass" [nzAutoTips]="autoTips" [nzNoColon]="true"
              [formGroup]="formConpass.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Ids</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzMode="tags"
                               formControlName="ids"
                               nzShowArrow="false"
                               [nzPlaceHolder]="'ID + [Enter]'"
                               [nzOptions]="listOfOption">
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalConpass(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarUserGuiding" (click)="confirmaEditarConpass()">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarMFA.modalVisible" [nzTitle]="'Alterar Regra de Login (MFA)'" [nzClosable]="true"
          (nzOnCancel)="fechar(formEditarMFA)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarMFA.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">MFA</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="loginMFA" style="width: 100%;"
                               [(ngModel)]="conta.loginMFA">
                        <nz-option *ngFor="let opt of mfaOptions" [nzLabel]="opt.label"
                                   [nzValue]="opt.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formEditarMFA)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="alterarLoginMFA()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formElasticSearch.modalVisible" [nzTitle]="'Alterar Elastic Search'"
          [nzClosable]="true"
          (nzOnCancel)="modalElasticSearch(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalElasticSearch"></nz-skeleton>
        <form nz-form *ngIf="!loadings.modalElasticSearch" [nzAutoTips]="autoTips" [nzNoColon]="true"
              [formGroup]="formElasticSearch.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Host</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="elasticsearch_host"
                               nzShowArrow="false"
                               [(ngModel)]="infraestrutura.elasticsearch_host"
                               [nzOptions]="comboHostsElasticSearch">
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalElasticSearch(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarElastcSearsh"
                (click)="confirmaEditarElasticSearch()">
            Confirmar
        </button>
    </div>
</nz-modal>

<!--<nz-modal [(nzVisible)]="formServidor.modalVisible" [nzTitle]="'Alterar Servidor'"-->
<!--          [nzClosable]="true"-->
<!--          (nzOnCancel)="modalServidor(false)" [nzWidth]="648">-->
<!--    <div nz-row *nzModalContent>-->
<!--        <nz-skeleton *ngIf="loadings.modalServidores"></nz-skeleton>-->
<!--        <form nz-form *ngIf="!loadings.modalServidores" [nzAutoTips]="autoTips" [nzNoColon]="true"-->
<!--              [formGroup]="formServidor.formGroup">-->
<!--            <nz-form-item>-->
<!--                <nz-form-label [nzSpan]="5">Servidor</nz-form-label>-->
<!--                <nz-form-control [nzSpan]="14">-->
<!--                    <nz-select formControlName="servidor_id"-->
<!--                               nzShowArrow="false"-->
<!--                               [(ngModel)]="infraestrutura.servidor_id"-->
<!--                               [nzOptions]="listOptsServidores">-->
<!--                    </nz-select>-->
<!--                </nz-form-control>-->
<!--            </nz-form-item>-->
<!--        </form>-->
<!--    </div>-->
<!--    <div *nzModalFooter>-->
<!--        <button nz-button nzType="link" (click)="modalServidor(false)">Cancelar</button>-->
<!--        <button nz-button nzType="primary" [nzLoading]="loadings.alterarElastcSearsh"-->
<!--                (click)="confirmaEditarServidor()">-->
<!--            Confirmar-->
<!--        </button>-->
<!--    </div>-->
<!--</nz-modal>-->


<nz-modal [(nzVisible)]="formInfra.modalVisible" [nzTitle]="'Alterar Infraestrutura'"
          [nzClosable]="true"
          (nzOnCancel)="modalInfra(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formInfra.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipoInfraestrutura">
                        <nz-option [nzLabel]="'Compartilhada'" [nzValue]="'compartilhada'"></nz-option>
                        <nz-option [nzLabel]="'Dedicada'" [nzValue]="'dedicada'"></nz-option>
                        <nz-option [nzLabel]="'Semi-Dedicada'" [nzValue]="'semi_dedicada'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalInfra(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterarInfra" (click)="confirmaEditarInfra()">
            Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formServidorRobosEmbedded.modalVisible" [nzTitle]="'Alterar servidor dos robôs Embedded'"
          [nzClosable]="true"
          (nzOnCancel)="modalServidorRobosEmbedded(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalAlterarServidorRobos"></nz-skeleton>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formServidorRobosEmbedded.formGroup"
        *ngIf="!loadings.modalAlterarServidorRobos">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Servidor</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="servidorRobosEmbedded" size="36"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalServidorRobosEmbedded(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.btnConfirmarServidorRobos" (click)="confirmaEditarServidorRobosEmbedded()">
            Confirmar
        </button>
    </div>
</nz-modal>
