import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'bitesBytes'})
export class BitesBytesPipe implements PipeTransform {

    transform(value: number, decimalPlaces: number = 1): string {

        if (!value || value < 0) {
            return '-';
        }

        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const index = Math.floor(Math.log(value) / Math.log(1024));
        const formattedValue = (value / Math.pow(1024, index)).toFixed(decimalPlaces);

        return `${formattedValue} ${units[index]}`;
    }

}
