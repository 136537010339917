<nz-page-header class="site-page-header" nzTitle="Monitoramento"
                nzSubtitle="Monitoramento de Dispositivos Agent">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Dispositivos Agent"
             [nzLoading]="loadings.table" [nzBodyStyle]="{'padding': loadings.table ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">

                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>

                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <form>
                        <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch" name="'mySearch' "
                               #mySearch (keyup)="onSearchKeydown($event, mySearch.value)">
                    </form>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="getRegistros(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>

                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="resetSearch();">
                    Limpar
                </button>

                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="registros"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="getRegistros($event)"
                  class="fonte-doze table-small" style="font-size: 12px;">
            <thead>
            <tr>
                <th style="width: 50px;" [nzShowSort]="false"></th>
                <th nzLeft [nzSortFn]="true" nzColumnKey="id">Conta</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Consumo desde</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Último Login</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Última Transmissão</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Consumo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registro of basicTable.data">
                <td></td>
                <td>
                    <a nz-button nzType="link" style="text-align: left; line-height: 20px;"
                       (click)="onModalDetalhe(true,  registro)">
                        <span style="font-weight: 600; font-size: 12px;">{{ registro.identificador }}</span><br>
                        {{ registro.conta_nome }}
                    </a>
                </td>
                <td nzAlign="center">{{ registro.consumoDesde ? (registro.consumoDesde | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td nzAlign="center">{{ registro.ultimoLogin ? (registro.ultimoLogin | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td nzAlign="center">{{ registro.ultimaTransmissao ? (registro.ultimaTransmissao | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td nzAlign="center">{{ registro.consumoTamanho | bitesBytes }}</td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>


    </nz-card>
</nz-content>


<nz-drawer
        [nzClosable]="true"
        [nzVisible]="visibles.drawer"
        nzPlacement="bottom"
        [nzTitle]="optionsDrawer.titulo"
        [nzHeight]="'550px'"
        (nzOnClose)="onModalDetalhe(false)"
>
    <ng-container *nzDrawerContent>

        <nz-skeleton *ngIf="loadings.drawer"></nz-skeleton>
        <nz-table #basicTable
                  nzSize="small"
                  *ngIf="!loadings.drawer"
                  [nzData]="registrosDetalhe"
                  [nzFrontPagination]="false"
                  class="fonte-doze table-small" style="font-size: 12px;">
            <thead>
            <tr>
                <th nzLeft [nzShowSort]="false">Id</th>
                <th nzAlign="center" [nzShowSort]="false">Status</th>
                <th nzAlign="center" [nzShowSort]="false">Usuário</th>
                <th nzAlign="center" [nzShowSort]="false">Dispositivo</th>
                <th nzAlign="center" [nzShowSort]="false">IP</th>
                <th nzAlign="center" [nzShowSort]="false">IP Interno</th>
                <th nzAlign="center" [nzShowSort]="false">Versão</th>
                <th nzAlign="center" [nzShowSort]="false">Último Login</th>
                <th nzAlign="center" [nzShowSort]="false">Última Transmissão</th>
                <th nzAlign="center" [nzShowSort]="false">Consumo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registro of basicTable.data">
                <td nzLeft>{{ registro.mac }}</td>
                <td nzAlign="center">
                    <span *ngIf="registro.status == 1 else statusInativo">
                        <nz-tag [nzColor]="'#87d068'">Ativo</nz-tag>
                    </span>
                        <ng-template #statusInativo>
                            <nz-tag>Desconectado</nz-tag>
                        </ng-template>
                </td>
                <td nzAlign="center">{{ registro.usuario_nome }}</td>
                <td nzAlign="center">{{ registro.dispositivo }}</td>
                <td nzAlign="center">{{ registro.ip }}</td>
                <td nzAlign="center">{{ registro.ipInterno }}</td>
                <td nzAlign="center">{{ registro.versao }}</td>
                <td nzAlign="center">{{ registro.ultimoLogin ? (registro.ultimoLogin | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td nzAlign="center">{{ registro.ultimaTransmissao ? (registro.ultimaTransmissao | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td nzAlign="center">{{ registro.tamanho  | bitesBytes}}</td>

            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>


    </ng-container>
</nz-drawer>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalFiltros"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%"
        *ngIf="!loadings.modalFiltros">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Conta</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.identificador">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="identificador">
                        <nz-option *ngFor="let opt of comboContas"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
