<nz-page-header class="site-page-header" nzTitle="Monitoramento"
                nzSubtitle="Monitoramento Baixas por Conta">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Baixas por conta"
             [nzLoading]="loadings.table" [nzBodyStyle]="{'padding': loadings.table ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  nzShowPagination
                  [nzFrontPagination]="true"
                  [nzPageSize]="10"
                  [nzData]="registros"
                  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
                  class="fonte-doze table-small" style="font-size: 12px;">
            <thead>
            <tr>
                <th style="width: 50px;" [nzShowSort]="false"></th>
                <th nzLeft style="padding-left: 20px;" [nzShowSort]="false">Conta</th>
                <th nzAlign="center" style="padding-left: 20px;" [nzShowSort]="false">Baixas com erro</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registro of basicTable.data">
                <td style="width: 50px;">{{registro.index}}</td>
                <td style="padding-left: 20px;">
                    {{registro.conta.nome}}
                    <br><small><b>({{registro.conta.identificador}})</b></small>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" (click)="getDetalhe(true, registro.index, registro.detalhe)"
                       *ngIf="!loadings.detalhe[registro.index]">
                        {{registro.qtdErros}}</a>

                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadings.detalhe[registro.index]"></span>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>


    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="modalOptions.visible" [nzTitle]="'Baixas com erro'" [nzClosable]="true"
          (nzOnCancel)="getDetalhe(false)" [nzWidth]="750">
    <div *nzModalContent>
        <nz-table #basicTable
                  nzSize="small"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"

                  [nzScroll]="{ y: '240px' }"
                  [nzData]="modalOptions.detalhe"
                  class="fonte-doze table-small" style="font-size: 12px;">
            <thead>
            <tr>
                <th nzLeft [nzShowSort]="false">CNPJ</th>
                <th nzAlign="center" [nzShowSort]="false">Obrigação</th>
                <th nzAlign="center" [nzShowSort]="false">QTDE</th>
                <th nzAlign="center" [nzShowSort]="false"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registro of modalOptions.detalhe">
                <td style="width: 50px;">{{(registro.cnpj)}}</td>
                <td style="width: 50px;" nzAlign="center">{{registro.obrigacao}}</td>
                <td style="width: 50px;" nzAlign="center">{{registro.qtde}}</td>
                <td style="width: 50px;" nzAlign="center">
                    <a nz-button nzType="link" [nz-tooltip]="'Reprocessar'"
                       *ngIf="!loadings.reprocessar[registro.cnpj + '-' + registro.obrigacao]"
                       (click)="onReprocessarPorCnpj(registro.cnpj + '-' + registro.obrigacao, registro.conta, registro.ids)">
                        <i class="fa-solid fa-rotate-right" *ngIf="!loadings.reprocessar[registro.cnpj + '-' + registro.obrigacao]"></i>
                    </a>
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadings.reprocessar[registro.cnpj + '-' + registro.obrigacao]"></span>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="getDetalhe(false)">Fechar</button>
    </div>
</nz-modal>


