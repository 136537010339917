import { NgModule } from '@angular/core';
import { HeaderComponent } from '@components/header/header.component';
import { MegaMenuComponent } from '@components/megamenu/megamenu.component';
import { NgZorroModule } from '../shared/ng-zorro.module';
import { HeaderFullScreenComponent } from '@components/header/fullscreen.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { UserMenuComponent } from '@components/header/user-menu/user-menu.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NavbarListComponent } from '@components/navbar-list/navbar-list.component';
import { TabsetModule } from '@components/tabset/tabset.module';
import { DeployInfo } from '@components/header/deploy-info/deploy-info';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {CpfCnpjPipe} from '../shared/pipes/cpfCnpj.pipe';
import {TelefonePipe} from '../shared/pipes/telefone.pipe';
import {CepPipe} from '../shared/pipes/cep.pipe';
import {NzImageModule} from 'ng-zorro-antd/image';
import { ContasLimitesComponent } from './relatorios/contas-limites/contas-limites.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {BitesBytesPipe} from "../shared/pipes/bitesBytes.pipe";

@NgModule({
    imports: [
        NgZorroModule,
        NzTreeModule,
        NzBadgeModule,
        NzTabsModule,
        CommonModule,
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NzResultModule,
        TabsetModule,
        NzTypographyModule,
        NzSpaceModule,
        NzSwitchModule,
        NzImageModule,
        NzPageHeaderModule,
        NzTagModule
    ],
    providers: [
        MegaMenuComponent,
    ],
    declarations: [
        HeaderComponent,
        MegaMenuComponent,
        HeaderFullScreenComponent,
        UserMenuComponent,
        NavbarListComponent,
        DeployInfo,
        DeployInfo,
        CpfCnpjPipe,
        TelefonePipe,
        CepPipe,
        ContasLimitesComponent,
        BitesBytesPipe
    ],
    exports: [
        HeaderComponent,
        NgZorroModule,
        HeaderFullScreenComponent,
        UserMenuComponent,
        MegaMenuComponent,
        NavbarListComponent,
        CpfCnpjPipe,
        TelefonePipe,
        BitesBytesPipe
    ]
})
export class UiModule {
}
