import { Injectable } from '@angular/core';
import {AbstractService} from "@services/abstract.service";
import {HttpClient} from "@angular/common/http";
import {Conta} from "@models/conta.model";

@Injectable({
  providedIn: 'root'
})
export class ContasInfraService extends AbstractService<Conta> {

  constructor(http: HttpClient) {
    super(http, '/contas', Conta);
  }
}
