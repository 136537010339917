import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class BaixasPorContaService extends AbstractService<Visibilidade> {

    baseUrls: { table: string } = {
        table: environment.apiUrl + '/monitoramento/baixas-por-conta'
    };

    constructor(http: HttpClient) {
        super(http, '/monitoramento/baixas-por-conta', Visibilidade);
    }

    getBaixas(filtros: { key: string, value: string }[] = []): any {

        const queryParams = [];
        filtros.forEach(value => {
            if (value.value) {
                queryParams.push(value.key + '=' + value.value);
            }
        });
        const queryStr = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.get(this.baseUrl + '/obrigacoes-erro/tabela' + queryStr);
    }

    reprocessarIds(identificador: string, ids: string[]) {
        return this.http.post(this.baseUrl + '/obrigacoes-erro/reprocessar', {identificador, ids});
    }
}
