import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class FiltrosService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/filtros', {});
    }

    contas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/contas/select`);
    }

    perfis(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/perfis/select`);
    }

    planos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/planos/select`);
    }

    modulos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/modulos/select`);
    }

    creditos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/creditos/select`);
    }

    visibilidades(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/visibilidades/select`);
    }

    servidores(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/servidores/select`);
    }

    servidoresConta(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/servidoresConta/select`);
    }


}
