import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class DispositivosAgentService extends AbstractService<any> {

    baseUrls: { table: string } = {
        table: environment.apiUrl + '/monitoramento/dispositivos-agent'
    };

    constructor(http: HttpClient) {
        super(http, '/monitoramento/dispositivos-agent', {});
    }

    detalhe(identificador): any {

        return this.http.get(environment.apiUrl + '/monitoramento/dispositivos-agent/detalhe/' + identificador);
    }
}
