import BulkChanges from '@models/entity.model';
import {Utils} from '../utils';
import {CrudModel} from '@models/crud.model';

export class Conta extends CrudModel implements BulkChanges {
    id: string;
    nome: string;
    identificador: string;
    tipo: string;
    ativo: number;
    // tslint:disable-next-line:variable-name
    updated_at: string;
    // tslint:disable-next-line:variable-name
    created_at: string;
    visibilidades: [];
    menuTipo: string;
    menuMerge: number;
    srcAvatar: string;
    srcLogo: string;
    // tslint:disable-next-line:variable-name
    plano_id: string;
    // tslint:disable-next-line:variable-name
    plano_descricao: string;
    legado: number;
    implantacao: number;
    manutencao: number;
    limiteEmpresas: number;
    limiteUsuarios: number;
    classificacao: string;
    // tslint:disable-next-line:variable-name
    visibilidade_id: string;
    // tslint:disable-next-line:variable-name
    visibilidade_descricao: string;
    loginMFA: any;
    usuarioCpfObrigatorio: any;
    campoLogin: string;
    modulos: [];
    dominioStatus: number;
    contratado: string;
    responsavelNome: string;
    responsavelDocumento: string;
    disabled: boolean;
    identificadores: [];
    loginSSO: number;
    constructor(obj?: any) {

        super(CrudModel);

        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }

        }

    }

    toBulk(ids: string | string[], itens: string[]) {

        return {
            ids,
            itens,
        };

    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
        return {
            usuarios: checkedItems,
            visibilidades: propertyChanged
        };
    }
}
