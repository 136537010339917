<nz-page-header class="site-page-header" nzTitle="Contas"
                nzSubtitle="Gerenciamento de Infraestruturas"></nz-page-header>
<nz-content>
    <nz-card [ngClass]="expandedCard ? 'card-fullscreen' : ''" [nzExtra]="cardExtra" nzTitle="Contas"
             [nzLoading]="loadings.card" [nzBodyStyle]="{'padding': loadings.card ? '15px' : '0px'}">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">

                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>

                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <form>
                        <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch" name="'mySearch' "
                               #mySearch (keyup)="onSearchKeydown($event, mySearch.value)">
                    </form>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button nzType="default"
                        style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                        (click)="updateTable();" title="Atualizar">
                    <i nz-icon nzType="reload"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>

        </ng-template>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>

        <nz-table #basicTable
                  [nzData]="contas"
                  [nzLoading]="loadings.table"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplate"
                  class="table-small fonte-doze">
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="left">Conta</th>
                <th nzColumnKey="identificador" [nzSortFn]="true" nzAlign="left">Identificador</th>
                <th nzColumnKey="ativo" [nzSortFn]="true" nzAlign="center">Status</th>
                <th nzColumnKey="manutencao" [nzSortFn]="true" nzAlign="center">Em manutenção</th>
                <th nzColumnKey="tipoInfraestrutura" [nzSortFn]="true" nzAlign="center">Tipo de Infraestrutura</th>
                <th nzColumnKey="servidorConta_nome" [nzSortFn]="true" nzAlign="center">Account Manager</th>
                <th nzColumnKey="bd_hostname" [nzSortFn]="true" nzAlign="center">Servidor</th>
                <th nzColumnKey="bd_database" [nzSortFn]="true" nzAlign="center">Base de dados</th>
                <th nzColumnKey="servidorRobosEmbedded" [nzSortFn]="true" nzAlign="center">Servidor Robôs Embedded</th>
                <th [nzShowSort]="false" [nzSortFn]="false" nzAlign="center">Infraestrutura pendente</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true" [nzSortOrder]="'descend'">Cadastro Data
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let conta of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(conta.id, $event)"
                    [nzChecked]="checkedItems.has(conta.id)"
                    [nzDisabled]="conta.disabled"
                    style="max-width: 50px;">
                </td>
                <td nzAlign="left" style="max-width: 200px; white-space: normal">
                    <a style="white-space: normal; width: 100%; text-align: left"
                       (click)="openTab('/conta/detalhe', conta.id, {id: conta.id, nome: conta.nome})">
                        <span>{{ conta.nome }}</span>
                    </a>
                </td>
                <td nzAlign="left" style="max-width: 200px; white-space: normal">
                    {{ Helpers.stringIdentificadores(conta.identificadores) }}
                </td>
                <td nzAlign="center" style="white-space: nowrap">
                    <nz-tag [nzColor]="'#f50'" *ngIf="!conta.ativo else contaAtiva"
                            style="margin-left: 10px; font-size: 10px">
                        Inativa
                    </nz-tag>
                    <ng-template #contaAtiva>
                        Ativa
                    </ng-template>
                </td>
                <td nzAlign="center" style="white-space: nowrap">
                    <nz-tag [nzColor]="'#f50'" *ngIf="conta.manutencao else naoManutencao"
                            style="margin-left: 10px; font-size: 10px">
                        Sim
                    </nz-tag>
                    <ng-template #naoManutencao>-</ng-template>
                </td>
                <td nzAlign="center">
                    {{ labelsTipoInfra[conta.tipoInfraestrutura] ?? '-' }}
                </td>
                <td nzAlign="center">
                    {{ conta.servidorConta_id ?? '-' }}
                </td>
                <td nzAlign="center">
                    {{ conta.bd_database && conta.servidor_nome ? conta.bd_database : '-' }}
                </td>
                <td nzAlign="center">
                    <span>{{ conta.servidor_nome ?? '-' }}</span>
                </td>
                <td nzAlign="center">
                    {{ conta.servidorRobosEmbedded ?? '-' }}
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link"
                       *ngIf="conta.ultimaSolicitacaoData && !conta.ultimaReportagemData else semSolicitacao"
                       [nz-tooltip]="extraTooltipSolicitacao">
                        <i class="fa-solid fa-bell"></i>
                    </a>
                    <ng-template #extraTooltipSolicitacao>
                        <small><b>Por</b></small> {{ conta.usuarioSolicitacao_nome }}
                        <br><small><b>Em</b></small> {{ conta.ultimaSolicitacaoData | date:'dd/MM/YY - HH:mm:ss' }}
                    </ng-template>
                    <ng-template #semSolicitacao>-</ng-template>
                </td>
                <td nzAlign="center">{{ conta?.created_at | date:'dd/MM/YY - HH:mm:ss' }}</td>

            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="7">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="10"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Monstrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="7">Em Manutencao</nz-form-label>
                <nz-form-control [nzSpan]="10"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.manutencao">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="manutencao">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7">Tipo de Infraestrutura</nz-form-label>
                <nz-form-control [nzSpan]="10"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.tipoInfraestrutura">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipoInfraestrutura">
                        <nz-option [nzLabel]="'Compartilhada'" [nzValue]="'compartilhada'"></nz-option>
                        <nz-option [nzLabel]="'Dedicada'" [nzValue]="'dedicada'"></nz-option>
                        <nz-option [nzLabel]="'Semi Dedicada'" [nzValue]="'semi_dedicada'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="7">Account Manager</nz-form-label>
                <nz-form-control [nzSpan]="10"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.servidorConta_id">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="servidorConta_id">
                        <nz-option *ngFor="let opt of selectOptions.servidoresConta" [nzLabel]="opt.nome"
                                   [nzValue]="opt.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="7">Servidor Base de Dados</nz-form-label>
                <nz-form-control [nzSpan]="10"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.servidor_id">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="servidor_id">
                        <nz-option *ngFor="let opt of selectOptions.servidores" [nzLabel]="opt.servidor"
                                   [nzValue]="opt.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="7">Servidor Robôs Embedded</nz-form-label>
                <nz-form-control [nzSpan]="10"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.servidorRobosEmbedded != null && formFiltrar.formGroup.value.servidorRobosEmbedded != ''">
                    <input nz-input type="text" formControlName="servidorRobosEmbedded" placeholder="Procurar">
                </nz-form-control>

            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="7">Cadastrado em</nz-form-label>
                <div nz-col [nzSpan]="10">
                    <div nz-row>
                        <nz-form-control nz-col [nzSpan]="11"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.cadastroDataDe">
                            <nz-date-picker formControlName="cadastroDataDe" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="De:" style="width: 100%;"
                            ></nz-date-picker>
                        </nz-form-control>
                        <nz-form-control nz-col [nzSpan]="11" [nzOffset]="2" class="text-right"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.cadastroDataAte">
                            <nz-date-picker formControlName="cadastroDataAte" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="Até:" style="width: 100%;"></nz-date-picker>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
